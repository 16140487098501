export default {  
	get(){
		return "/listForms"
	},
	setAlert(){
		return "setalertform"
	},
    getAlert(){
        return "getalert"
    },
	update() {
		return "updateforms"
	},
	duplicarForm(){
		return "/copyforms"
	},
	del(){
		return "/deleteForms"
	},
	add(){
		return "/autoCreateForms"
	},
	getSetting(id){
		return "getnoti/" + window.localuserdata +"/"+ id
	},
	setNoti(){
		return "setnoti"
	},
	sendtest(){
		return "form/sendtest"
	},	
	sendtestsms(){
		return "form/sendtestsms"
	},
	formSMSOnOff(){
		return "form/smsOnOff"
	},
};
