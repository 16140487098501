<template>
	<div>
		<modal 
			name="modal-lateral" 
			height="auto" 
			@before-close="beforeClose"
			class="modalLateral"
			v-bind:click-to-close="true"
		>
			<div class="card">
				<button @click="closeModal" class="closeIcon" style="width: 50px;"><span></span></button>
				<Loader v-if="isLoading" class="bgloading"></Loader>
				<slot></slot>
			</div>
		</modal>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import Loader from '@/core/components/shared/Loader'
	import "@/core/plugins/themeModalLateral/styles.css";
	export default {
		name: 'modalLateral',
		components: {
			Loader
		},
		props: {
            dataModal: {
                type: [Object],
                default: () => {}
            }
        },
		data () {
			return {
				title: '',
				nameComponent: '',
				isLoading: false,
				closing: false,
			}
		},
		methods: {
			closeModal() {
				console.log('closeModal')
				this.$store.commit("core/setCloseModal", false);
				var elem = document.querySelector('.vm--modal');
				if(elem){elem.classList.add('closed');}
				this.$modal.hide("modal-lateral");
			},
			closeModal2() {
				console.log('closeModal2')
				var elem = document.querySelector('.vm--modal');
				if(elem){elem.classList.add('closed');}
				this.$modal.hide("modal-lateral");
			},
			beforeClose() {
				let t = this;
				t.closing=true;
				if(t.$store.getters["core/getOpenModal"].show==true){
					setTimeout(function(){
					console.log('beforeModalClose')
						t.$store.commit("core/setCloseModal", true);
					}, 1000);
				}
			},
		},
		watch: {
			openModal: function () {
				//console.log('openModal', this.openModal);
				this.nameComponent = this.openModal.component;
				this.title = this.openModal?.data?.title ?? null;
				if (this.openModal.show) {
					this.$modal.show("modal-lateral");
					this.closing=false;
				}
				else {
					if(this.closing==true){
						if(this.openModal.component!=null && this.openModal.component.length>0){this.closeModal2();}
					}else{
						if(this.openModal.component!=null && this.openModal.component.length>0){this.closeModal()}
					}
				}
				if(window.innerWidth <=600){
					console.log('modal en responsive');
					let elem =document.querySelector('.page-wrapper');
					if(elem && this.openModal.show) {
						elem.style.zIndex="100";
					}
					else if(elem && !this.openModal.show) {
						elem.style.zIndex="1";
					}
				}
			},
			LoadingModal: function() {
				this.isLoading = this.LoadingModal;
			}
		},
		computed: {
			...mapGetters('core', {openModal: 'getOpenModal', LoadingModal: 'getLoadingModal'})
		}
	};
</script>
<style lang="css" scoped>
.bgloading {
    background-color: rgb(255 255 255 / 50%);
}
.closeIcon {
    right: 1px;
    top: 2px;
    height: 40px;
    width: 40px;
}
.closeIcon span:before {
    width: 40px;
}
.closeIcon span:after {
    width: 40px;
}
</style>
