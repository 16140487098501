/*rutas de invoice*/
// import Invoices from '@/apps/billing/views/Invoices';
import Invoices from '@/apps/billing/views/invoice/';
// import InvoicesNew from '@/apps/billing/views/InvoicesNew';
import InvoicesNew from '@/apps/billing/views/invoice/InvoForm';
export default [
  {
  	path: 'invoices',
  	name: 'Invoices',
    folder: Invoices,
  },
  {
    path: 'invoices/new',
    name: 'InvoicesNew',
    folder: InvoicesNew,
  },
	{
	  path: 'invoice/:idinv/:edit',
	  name: 'InvoiceDet',
    folder: InvoicesNew,
	},
	{
	  path: 'invoice/:idc/:transac',
	  name: 'InvoiceNewTransac',
    folder: InvoicesNew,
	},
	{
	  path: 'invoice/:idinv/:duplicate',
	  name: 'InvoiceDuplicate',
    folder: InvoicesNew,
	},
  {
    path: 'convert-est-to-inv/:idest',
    name: 'EstConvertInvoice',
    folder: InvoicesNew,
  }
];
