<template>
	<div>
		<notifications 
			group="noti" 
			position="top center"			
			animation-name="v-fade-left"
			class="vue-notification-notiPush"
		>
			<template slot="body" slot-scope="{ item, close }">
				<div v-bind:class="[
						'notiPush', item.type,
						''
					]"
				>
					<div class="notiPush-icon">
						<i v-bind:class="[
								'fas', 
								(item.type=='success') ? 'fa-check-circle' :
								(item.type=='error') ? 'fa-times-circle' : '',
								' fa-fw '
							]"
						></i>
					</div>
					<div class="notiPush-content">
						<div class="notiPush-title">{{ item.title }}</div>
						<div class="notiPush-message" v-html="item.text"></div>
					</div>
					<div class="notiPush-close">
						<button class="close" @click="close">
							 <i class="fa fa-times"></i>
						</button>
					</div>
				</div>
			</template>
		</notifications>
		<notifications 
			group="notiPush" 
			position="top center"			
			animation-name="v-fade-left"
			class="vue-notification-notiPush"
		>
			<template slot="body" slot-scope="{ item, close }">
				<div v-bind:class="[
						'notiPush',
							(item.type==1) ? 'success' :
							(item.type==2) ? 'info' : 
							(item.type==3) ? 'error' :
							(item.type==4) ? 'refresh' :
							(item.type==5) ? 'warning' : '',
						''
					]"
				>
					<div class="notiPush-icon">
						<i v-bind:class="[
								'fas', 
								(item.type==1) ? 'fa-check-circle' :
								(item.type==2) ? 'fa-info-circle' : 
								(item.type==3) ? 'fa-times-circle' :
								(item.type==4) ? 'fa-hourglass-half' :
								(item.type==5) ? 'fa-exclamation-triangle' : '',
								' fa-fw '
							]"
						></i>
					</div>
					<div class="notiPush-content">
						<div 
							v-bind:class="[
								'notiPush-title',
									(item.type==1) ? 'success' :
									(item.type==2) ? 'info' : 
									(item.type==3) ? 'error' :
									(item.type==4) ? 'refresh' :
									(item.type==5) ? 'warning' : '',
								''
							]"
						>{{ item.title }}</div>
						<div 
							v-bind:class="[
								'notiPush-message',
									(item.type==1) ? 'success' :
									(item.type==2) ? 'info' : 
									(item.type==3) ? 'error' :
									(item.type==4) ? 'refresh' :
									(item.type==5) ? 'warning' : '',
								''
							]"
							v-html="item.text"
						></div>
					</div>
					<div 
						v-bind:class="[
								'notiPush-close',
									(item.type==1) ? 'success' :
									(item.type==2) ? 'info' : 
									(item.type==3) ? 'error' :
									(item.type==4) ? 'refresh' :
									(item.type==5) ? 'warning' : '',
								''
							]"
					>
						<button class="close" @click="close">
							 <i class="fa fa-times"></i>
						</button>
					</div>
				</div>
			</template>
		</notifications>
		<notifications
		group="update-item"
		:width="160"
		animation-name="v-fade-left"
		position="top center"
		>
			<template slot="body">
				<div class="custom-template">
					<div class="custom-template-content">
						<div class="custom-template-title">
							<span class="icon"><i class="fas fa-hourglass-half"></i></span>
							<span class="title">{{ noti.text }}</span>
						</div>
					</div>
				</div>
			</template>
		</notifications>
		<notifications 
			group="notiPushPremium" 
			position="top center"	
			animation-name="v-fade-left"
			class="vue-notification-notiPushPremium"
		>
			<template slot="body" slot-scope="{ item, close }">
				<div class="notiPush Premium light"
				>
					<div class="notiPush-icon">
						<i class="fas fa-info-circle fa-fw"></i>
					</div>
					<div class="notiPush-content Premium">
						<div class="notiPush-title">This feature is available for PREMIUM USERS.</div>
						<div class="notiPush-message">
							<h4>Upgrade your App to Premium and advanced features. Premium Plan cost $50/month.</h4>
						</div>
						<button 
							type="button"
							class="btn btn-success"
							style="display: inline-flex;justify-content: center; width: 160px; margin-right: 10px;"
							@click="setPremium"
						>
							UNLOCK PREMIUM
						</button>
						<button 
							type="button"
							class="btn btn-secondary"
							style="display: inline-flex;justify-content: center; width: 100px;"
							@click="close()"
						>
							CLOSE
						</button>
					</div>
					<div class="notiPush-close Premium">
						<button class="close" @click="close()">
							<i class="fa fa-times"></i>
						</button>
					</div>
				</div>
			</template>
		</notifications>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	name: 'notiPusher',
	data () {
		return {
			noti: {
				title: 'Success',
				text: '',
				type: '1', // 1=success | 2=info | 3=error | 4=loading | 5=warning
				duration: 3000
			},
		}
	},
	created(){
	},
	methods: {
		hideNotiPush(){
			this.$store.commit("core/setNotiPushHide");
		},
		notificacionesPush(i) {
			this.noti = {
				group: i.group,
				type: i.type,
				title: i.title,
				text: i.text,
				duration: i.duration || 3000
			};
			this.$notify(this.noti);
			this.$store.commit("core/setNotiPushHide");
		},
		notiLoading (i) {
			this.noti.text = i.text;
			this.$notify({
				group: "update-item",
				type: "",
				title: "",
				text: "",
				duration: i.duration
			});
		},
		setPremium(){
			this.$store.commit('set_isLoading', true);
			if(localStorage.getItem('appIDactual')==1){
				this.$store.dispatch("core/Org/PlanPremium").then((response) => {
					console.log('setPlanPremium', response)
					this.notificacionesPush({
						group: 'notiPush',
						type: 1,
						title: 'Succes!',
						text: ' Premium was unlocked.',
					});
					location.reload(true);
					// this.$store.commit('set_isLoading', false);
				})
				.catch((error) => {
					console.log('setPlanPremium', error);
					this.$store.commit('set_isLoading', false);
				});
			}else{				
				this.$store.dispatch("core/apps/setPlanApp", localStorage.getItem('appIDactual')).then((response) => {
					console.log('setPlanApp', response)
					this.notificacionesPush({
						group: 'notiPush',
						type: 1,
						title: 'Succes!',
						text: 'Plan Upgrade',
					});
					setTimeout(function(){
						location.reload(true);
					}, 1000);
					// this.$store.commit('set_isLoading', false);
				})
				.catch((error) => {
					console.log('setPlanPremium', error);
					this.$store.commit('set_isLoading', false);
				});
			}
		},
		moreDetailsPremium(){
			window.open(window.urlweb+'billing', "_blank");
		}
	},
	watch: {
		notiPush: function () {
			if (this.notiPush.show) {
				this.notificacionesPush(this.notiPush);
			}
		},
		notiUpdate: function () {
			if (this.notiUpdate.show) {
				this.hideNotiPush();
				this.notiLoading(this.notiUpdate);
			}
		},
		destroyAll(){
			// console.log('destroyAll', this.destroyAll);
			document.querySelectorAll(".vue-notification-notiPush .notiPush").forEach(el => el.remove());
		}
	},
	computed: {
		...mapGetters("core", { 
			notiPush: "getNotiPush", 
			notiUpdate: "getNotiUpdate",
			destroyAll: "getDestroyAll",
		}),
	}
};
</script>
<style lang="scss" scoped>
.custom-template {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin: 5px;
	margin-bottom: 0;
	align-items: center;
	justify-content: center;
	&,
	& > div {
		box-sizing: border-box;
	}
	background: #3028de;
	border: 2px solid #3028de;
	border-radius: 4px;
	.custom-template-content {
		padding: 10px;
		flex: 1 0 auto;
		.custom-template-title {
			color: #fff;
			.icon {
				font-size: 14px;
				margin-right: 5px;
			}
			.title {
				letter-spacing: 1px;
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 600;
			}
		}
	}
}
.notiPush {
    border: 0px solid;
    display: flex;
    background: #827d7d;
    border-left: 5px solid #565555;
    padding: 4px 0px;
    width: 300px;
    padding-right: 5px;
    color: #eee;
    flex-wrap: nowrap;
    margin-bottom: 10px;
}
.notiPush.error {
    background: #e54d42;
    border-left-color: #b82e24;
}
.notiPush.success {
    background: #68cd86;
    border-left-color: #42a85f;
}
.notiPush.info {
    background: #44a4fc;
    border-left-color: #187fe7;
}
.notiPush.warning {
	background: #e4ba34;
    border-left-color: #d2a106;
}.notiPush.light {
	background: #fff;
    border-left-color: #ccc;
}
.notiPush-icon {
    padding: 5px;
    font-size: 20px;
    padding-right: 8px;
}

.notiPush-content {
    width: 235px;
    box-sizing: border-box;
}

.notiPush-title {
    font-size: 16px;
    font-weight: bold;
}

.notiPush-message {
    font-size: 14px;
}

.notiPush-close button.close {
    color: #fff;
    background: transparent;
    opacity: 1;
    font-weight: 100;
    text-shadow: none;
    font-size: 14px;
}
.vue-notification-notiPush{
	top: 5px !important;
	z-index: 999999;
}
.vue-notification-notiPushPremium{
	z-index: 99999;
	top: 52px !important;
    left: initial !important;
    right: 1% !important;
}
.vue-notification-notiPushPremium,
.notiPush.Premium{
	width: 350px!important;
	color: #555;
}
.notiPush-content.Premium{
	width: 335px;
	margin-bottom: 10px;
}
.notiPush.Premium,
.notiPush-close.Premium button.close{
	color: #555;
}
.notiPush.Premium {
    border: 1px solid #555;
    border-left: 5px solid #ccc;
}
.notiPush-title.warning,
.notiPush-message.warning,
.notiPush-close.warning button.close{
	color: #555;
}
</style>
