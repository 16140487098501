<template>
    <div class="row">
        <div class="col-12  text-right" style="padding-right: 20px;" id="show_group_taxes" v-if="!disabledSteps.customer">
            <!-- <div class="form-group row"
                style="width:300px;display: inline-flex;align-content: center;align-items: center;">
                <label class="col-4" for="Group">Group</label>
                <select class="form-control col-4" v-model="groupShow" id="Group">
                    <option :value="0">None</option>
                    <option :value="1">Show</option>
                </select>
            </div> -->
            <div class="form-check form-check-inline" style="width:200px;justify-content: flex-start;">
                <label for="activeGroupShow" style="margin-right: 10px;"> Group: </label>
                <div class="onoffswitch">
                    <input type="checkbox" class="onoffswitch-checkbox" id="activeGroupShow" :value="1" v-model="groupShow"/>
                    <label class="onoffswitch-label" for="activeGroupShow">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                    </label>
                </div>
            </div>
            <div class="form-check form-check-inline" style="width:200px;justify-content: flex-start;">
                <label for="activeTaxShow" style="margin-right: 10px;"> Taxes: </label>
                <div class="onoffswitch">
                    <input type="checkbox" class="onoffswitch-checkbox" id="activeTaxShow" :value="1" v-model="taxShow"/>
                    <label class="onoffswitch-label" for="activeTaxShow">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="table-productos" v-if="!disabledSteps.customer">
            <table class="table table-striped">
                <thead style="background-color: #6b6b6b">
                    <tr>
                        <th style="color: #fff;">Product / Service</th>
                        <!-- <th style="color: #fff;">Descripction</th> -->
                        <th style="color: #fff;" class="text-right">Quantity</th>
                        <th style="color: #fff;" class="text-right">Unit Cost</th>
                        <th v-if="taxShow == 1" style="color: #fff;" class="text-center">Tax</th>
                        <th style="color: #fff;" class="text-right">Price</th>
                    </tr>
                </thead>
                <draggable v-model="itemProducts" tag="tbody" class="tbody" v-bind="dragOptions"
                    @start="isDragging(true)" @end="endDragging(false)" handle=".handle">
                    <template v-for="(item, index) in itemProducts">
                        <tr v-if="item.model.value != idseparador && item.model.value != idcontinue && item.model.value != idGroup"
                            :class="['tr-products item-porduct-' + index, 'tr-product-' + item.model.value]" :key="index">
                            <td class="itemAndDescrip" style="">
                                <div class="btnmove handle" style="line-height: 50px;">
                                    <!-- <i class="fas fa-ellipsis-v"></i> -->
                                    <iconoMove />
                                </div>
                                <button type="button" @click="NewProducts(item, index)"
                                    :class="['btnAddItem', (disabledSteps.customer) ? 'disabled' : '']"
                                    :disabled="disabledSteps.customer" title="New Product / Service">+</button>
                                <model-select :options="item.options" v-model="itemProducts[index].model"
                                    placeholder="Choose a Products"
                                    :class="['productos', (disabledSteps.customer) ? 'disabled' : '']"
                                    :disabled="disabledSteps.customer"></model-select>
                                <div class="descripcion">
                                    <textarea :class="['form-control auto-height', (disabledSteps.customer) ? 'disabled' : '']"
                                        :disabled="disabledSteps.customer" placeholder="Product description"
                                        @input="continueDes(index, 900)"
                                        v-model="itemProducts[index].model.descripcion"></textarea>
                                    <span class="maxSize">
                                        {{ (item.model.descripcion != null) ? item.model.descripcion.length : 0 }}/900
                                    </span>
                                </div>
                            </td>
                            <td :class="['quantity', 'quantity-' + index, 'showtooltip']">
                                <input type="number"
                                    :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                    :disabled="disabledSteps.customer" placeholder="E.g. 1.00" v-model="item.model.qty"
                                    step="0.01" min="0" max="100000" @keypress="quantityIsInteger" @change="isInterger"
                                    @keyup="lengMax($event, 8)" />
                                <span class="tooltip">Error, wrong format, maximum 2 decimals.</span>
                            </td>
                            <td :class="['cost', 'cost-' + index, 'showtooltip']">
                                <input type="number"
                                    :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                    :disabled="disabledSteps.customer" placeholder="E.g. 1.00"
                                    v-model="item.model.unitcost" step="0.01" min="0" max="999999999.99"
                                    @keypress="quantityIsInteger" @change="isInterger" @keyup="lengMax($event, 12)" />
                                <span class="tooltip">Error, wrong format, maximum 2 decimals.</span>
                            </td>
                            <td class="taxselect" v-if="taxShow == 1">
                                <select class="form-control" v-model="item.model.taxOpc">
                                    <template v-for="(t, taxIdx) in item.taxlistOption">
                                        <option :key="taxIdx + 'index'" :value="t" v-if="t != 0">
                                            {{ t.initialstate }} {{ t.name }}
                                        </option>
                                        <option :key="taxIdx" :value="t" v-else>
                                            {{ t.initialstate }} {{ t.name }}
                                        </option>
                                    </template>
                                </select>
                            </td>
                            <td class="importe">
                                <strong>${{ (item.model.prices = calcPriceItem(index)) | formatMoney }}</strong>
                                <span class="btndelete" v-if="validRemoveItem" @click="removeItem(index)">x</span>
                            </td>
                        </tr>
                        <tr v-else-if="item.model.value == idGroup"
                            :class="['tr-products item-porduct-' + index, 'item-separador-' + index, 'tr-product-' + item.model.value, (groupShow == 0) ? 'tr-none' : '']"
                            :key="index">
                            <td :colspan="(taxShow == 1) ? '5' : '4'" class="item-separador-header group">
                                <button type="button" @click="addLineFromGroup(index)"
                                    :class="['btnAddItem', (disabledSteps.customer) ? 'disabled' : '']"
                                    :disabled="disabledSteps.customer" title="Add item"><i
                                        class="fas fa-level-up-alt"></i></button>
                                <input type="text" placeholder="Text Line" maxlength="126" required
                                    :class="['form-control name-group', (disabledSteps.customer) ? 'disabled' : '']"
                                    :disabled="disabledSteps.customer"
                                    v-model="itemProducts[index].model.descripcion" />
                                <span class="maxSize">
                                    {{ (item.model.descripcion != null) ? item.model.descripcion.length : 0 }}/126
                                </span>
                                <div class="totalGroup">( ${{ calcTotalGroup(index) | formatMoney }} )</div>
                                <span class="btndelete" v-if="validRemoveGroup && index > 0"
                                    @click="removeItem(index)">x</span>
                            </td>
                        </tr>
                        <tr v-else-if="itemProducts[index].model.value == idcontinue"
                            :class="['tr-products item-porduct-' + index, 'item-separador-' + index, 'tr-product-' + item.model.value]"
                            :key="index">
                            <td class="itemAndDescrip">
                                <div class="btnmove handle" style="line-height: 50px;">
                                    <iconoMove />
                                </div>
                                <div class="descripcion">
                                    <textarea :class="['form-control auto-height', (disabledSteps.customer) ? 'disabled' : '']"
                                        :disabled="disabledSteps.customer" placeholder="Product description"
                                        @input="continueDes(index, 900)"
                                        v-model="itemProducts[index].model.descripcion"></textarea>
                                    <span class="maxSize">
                                        {{ (item.model.descripcion != null) ? item.model.descripcion.length : 0 }}/900
                                    </span>
                                </div>
                            </td>
                            <td class="quantity"></td>
                            <td class="cost"></td>
                            <td class="taxselect" v-if="taxShow == 1"></td>
                            <td class="importe">
                                <span class="btndelete" v-if="itemProducts.length > 1" @click="removeItem(index)">x</span>
                            </td>
                        </tr>
                        <tr v-else
                            :class="['tr-products item-porduct-' + index, 'item-separador-' + index, 'tr-product-' + item.model.value]"
                            :key="index">
                            <td :colspan="(taxShow == 1) ? '5' : '4'" class="item-separador-header">
                                <div class="btnmove handle" style="line-height: 50px;">
                                    <iconoMove />
                                </div>
                                <input type="text" placeholder="Text Line" maxlength="130" required
                                    :class="['form-control', (disabledSteps.customer) ? 'disabled' : '']"
                                    :disabled="disabledSteps.customer"
                                    v-model="itemProducts[index].model.descripcion" />
                                <span class="maxSize">
                                    {{ (item.model.descripcion != null) ? item.model.descripcion.length : 0 }}/130
                                </span>
                                <span class="btndelete" v-if="itemProducts.length > 1" @click="removeItem(index)">x</span>
                            </td>
                        </tr>
                    </template>
                </draggable>
                <tfoot>
                    <tr>
                        <th :colspan="(taxShow == 1) ? '5' : '4'" class="text-left">
                            <button type="button"
                                :class="['btn btn-info btn-sm', (disabledSteps.customer) ? 'disabled' : '']"
                                :disabled="disabledSteps.customer" style="margin-right: 25px;" @click="addNewLine">
                                + Product
                            </button>
                            <button type="button"
                                :class="['btn btn-info btn-sm', (disabledSteps.customer) ? 'disabled' : '']"
                                :disabled="disabledSteps.customer" style="margin-right: 25px;" @click="lineSeparator">
                                Add Text Line
                            </button>
                            <!-- <button
                                            type="button"
                                            :class="['btn btn-info btn-sm', (disabledSteps.customer) ? 'disabled' : '']"
                                            :disabled="disabledSteps.customer"
                                            @click="continueDes"
                                        >
                                            continueDes
                                        </button> -->
                            <button v-if="groupShow == 1" type="button"
                                :class="['btn btn-info btn-sm', (disabledSteps.customer) ? 'disabled' : '']"
                                :disabled="disabledSteps.customer" @click="addNewLineWithGroup">
                                Add Group
                            </button>
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment';
import { ModelSelect } from 'vue-search-select';
import draggable from 'vuedraggable';
import iconoMove from './tbProducts/iconoMove.vue'
export default {
    props: [
        "controlLoad",
        "propForm",
        "propdisabledSteps",
        "propItemsprod",
        "proptaxShow",
        "propgroupShow",
    ],
    components: {
        "model-select": ModelSelect,
        draggable,
        iconoMove,
    },
    data() {
        return {
            form: this.propForm,
            taxShow: this.proptaxShow,
            groupShow: this.propgroupShow || 0,
            itemsprod: this.propItemsprod,
            disabledSteps: this.propdisabledSteps,
            TypeofAccount: [],
            TaxesLists: [],
            taxesResumen: [],
            groupItem: [],
            ProdServiceLists: [],
            itemProducts: [],
            prevItemProducts: [],
            idseparador: 999999999,
            idcontinue: 999999998,
            idGroup: 999999997,
            productDefault: {
                value: 0,
                text: "Uncategorized",
                description: "",
                unitcost: "1.00",
                qty: "1.00",
                taxOpc: {
                    id: 0,
                    name: 'None',
                    porcentaje: '',
                    initialstate: '',
                    statename: '',
                    count: 0,
                    total: 0,
                },
                applytax: 0,
                prices: 0
            },
            taxDefault: {
                id: 0,
                name: 'None',
                porcentaje: '',
                initialstate: '',
                statename: '',
                count: 0,
                total: 0,
            },
            FormAddPS: {
                vatError: false,
                vatErrorMsg: null,
                index_modal: null,
                btnName: "SAVE",
                Action: "Add",
                id: 0,
                productname: null,
                account_id: 0,
                quantity: 1,
                unitcost: 0,
                description: null,
                applytax: 0,
                status: 0,
            },
        };
    },
    created(){
        // console.log('created table_ePro taxShow', this.taxShow)
        this.init();
    },
    watch: {
        controlLoad: function(){
            if(this.controlLoad){
                // console.log('watch table_ePro taxShow', this.taxShow)
                this.init();
            }
        },
        'getActionRefresh': function() {
            let t =this;
            if (Object.keys(t.getActionRefresh.data).length > 0) {
                let data = t.getActionRefresh.data;
                let methods = t.getActionRefresh.action;
                if (methods === 'products') {
                    // console.log('getActionRefresh', t.getActionRefresh)
                    t.ProdServiceLists = JSON.parse(localStorage.getItem('ProdServiceLists'));
                    t.ProdServiceLists.push(data.model);
                    localStorage.setItem('ProdServiceLists', JSON.stringify(t.ProdServiceLists));
                    t.itemProducts[t.FormAddPS.index_modal].options = t.ProdServiceLists;
                    t.itemProducts[t.FormAddPS.index_modal].model = data.model;
                    localStorage.removeItem('ProdServiceLists');
                    t.getProdservices(1);
                }
            }
        },
        itemProducts: {
            handler: function (newVal) {
                let t = this;
                setTimeout(() => {
                    t.calcHeigthDescrip(); // refrescar el height de los textarea
                    // t.calcPrices(newVal); // calcular precios
                    t.prevItemProducts = newVal; // actualizar objeto con registros old
                    t.setupdate() // actualizar componente padre
                }, 500);
            },
            deep: true, // Necesario para observar cambios en los objetos dentro del array
            immediate: true, // Llamar al watch inmediatamente al cargar
        },
        taxShow: function(){
            let t = this;
            // console.log('watch taxShow', t.taxShow)
            t.setupdate() // actualizar componente padre
        },
        groupShow: function(){
            let t = this;
            t.setupdate() // actualizar componente padre
        }
    },
    methods: {
        init(){
            let t = this;
            localStorage.setItem('groupItem', JSON.stringify(t.groupItem));
            localStorage.setItem('productDefault', JSON.stringify(t.productDefault));
            localStorage.setItem('taxDefault', JSON.stringify(t.taxDefault));
            t.getAccountTypes();
            t.getTaxes().then((resulTaxes) => {
                // console.log('getTaxes', resulTaxes)
                t.getProdservices().then((resulProd) => {
                    t.getProductsOfEstByID()
                });
            });
        },
        setupdate(){
            let t = this;
            t.$emit('updateProdcts', {
                "taxShow": Number(t.taxShow),
                "groupShow": Number(t.groupShow),
                "itemProducts": t.itemProducts,
                "taxesResumen": t.taxesResumen,
            });
        },
        detectChanges(newVal, oldVal) {
            newVal.forEach((item, index) => {
                if (item?.model?.descripcion !== oldVal[index]?.model?.descripcion) {
                    // console.log(`El nombre del elemento con ID ${item.id} ha cambiado.`);
                    return true; // Detener la iteración
                }
            });
        },
        calcHeigthDescrip(){
            let t = this;
            let descrip = document.querySelectorAll('.auto-height');
            if(descrip!= null && descrip.length>0){
                descrip.forEach(e => {
                    //console.log('descrip', e.value)
                    if(e.value!=null && e.value!=''){
                        t.autoHeigthDescrip(e);
                    }
                })
            }
        },
        autoHeigthDescrip(elem){
            let calcHeight = elem;
            if(calcHeight){
                let numberOfLineBreaks = (calcHeight.value.match(/\n/g) || [1]).length;
                let logitud = Math.round(calcHeight.clientWidth) / 14 + 10; //elemWidth + font-size + line-height
                let caracteres =calcHeight.value.length*14/calcHeight.clientWidth;
                numberOfLineBreaks = (numberOfLineBreaks==1) ? caracteres : numberOfLineBreaks;
                //console.log('logitud', logitud)
                // min-height + lines x line-height + padding + border
                let newHeight = 14 + numberOfLineBreaks * 10 + 0 + 0;
                if(newHeight<40){newHeight=40;}
                calcHeight.style.height = newHeight + "px";
                calcHeight.style.maxHeight = newHeight + "px";
                if(numberOfLineBreaks>2 && calcHeight.scrollHeight>newHeight){
                    calcHeight.style.height = calcHeight.scrollHeight + "px";
                    calcHeight.style.maxHeight = calcHeight.scrollHeight + "px";
                    // console.log(calcHeight, calcHeight.style.height)
                }
            }
        },
        adjustHeight(textarea) {
            textarea.style.height = "auto"; // Restablece la altura para recalcular
            textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta al contenido
        },
        validDateUpdateStorage(varDate, varStorage){
            if(localStorage.getItem(varDate)!=null){
                let dateStorage = new Date(localStorage.getItem(varDate, varStorage));
                let dateActual = new Date();
                var fecha1 = moment(dateActual);
                var fecha2 = moment(dateStorage);
                let minutes = fecha1.diff(fecha2, 'minutes');
                if(minutes>5){
                    localStorage.removeItem(varStorage);
                }
            }
        },
        setDateStorage(varDate){
            let fecha = new Date().getFullYear()+'/'+(new Date().getMonth()+1)+'/'+new Date().getDate();
            let hora = new Date().getHours()+':'+new Date().getMinutes()+':0';
            let dateStorage = fecha+' '+hora;
            localStorage.setItem(varDate, dateStorage);
        },
        getAccountTypes() {
            let t = this;
            t.$emit('loadingData', {name: 'getAccountTypes', status:'pending'});
            window.billing
            .get("chart-account-list/" + window.localuserdata)
            .then((response) => {
                t.$emit('loadingData', {name: 'getAccountTypes', status:'finish'});
                // console.log('getAccountTypes', response)
                t.TypeofAccount = response.data.result;
            })
            .catch((error) => {
                t.$emit('loadingData', {name: 'getAccountTypes', status:'error'});
                console.log(error);
            });
        },
        getTaxes() {
            let t = this;
            t.$emit('loadingData', {name: 'getTaxes', status: 'pending'});
            return new Promise((resolve, reject) => {
                window.billing.get("taxes/" + window.localuserdata)
                .then((response) => {
                    // console.log("taxes request all", response.data);
                    //this.TaxesLists = response.data;
                    const ObjTaxes = [JSON.parse(localStorage.getItem('taxDefault'))];
                    if (response.data) {
                        for (let item in response.data) {
                            var id = response.data[item].id;
                            var initialstate = response.data[item].initialstate;
                            var name = response.data[item].name;
                            var state_id = response.data[item].state_id;
                            var statename = response.data[item].statename;
                            var status = response.data[item].status;
                            var user_id = response.data[item].user_id;
                            ObjTaxes.push({
                                id: id,
                                initialstate: initialstate,
                                name: name,
                                state_id: state_id,
                                statename: statename,
                                status: status,
                                user_id: user_id,
                                default: "none",
                            });
                            t.taxesResumen.push({
                                id: id,
                                name: initialstate,
                                porcentaje: name,
                                initialstate: initialstate,
                                statename: statename,
                                count: 0,
                                total: 0,
                            });
                        }
                        // console.log('taxesResumen', t.taxesResumen)
                        t.$emit('loadingData', {name: 'getTaxes', status: 'finish'});
                    }
                    t.TaxesLists = ObjTaxes;
                    localStorage.setItem('TaxesLists', JSON.stringify(this.TaxesLists));
                    resolve(true);
                })
                .catch((error) => {
                    t.$emit('loadingData', {name: 'getTaxes', status: 'error'});
                    console.log(error);
                    resolve(false);
                });
            });
        },
        getProdservices(load) {
            let t = this;
            t.$emit('loadingData', {name: 'getProdservices', status:'pending'});
            return new Promise((resolve, reject) => {
                t.validDateUpdateStorage('ProdServiceListsDate', 'ProdServiceLists');
                if(localStorage.getItem('ProdServiceLists')==null){
                    window.billing
                    .get("product-services/" + window.localuserdata)
                    .then((response) => {
                        // console.log("product sevice list table");
                        const ProdList = [JSON.parse(localStorage.getItem('productDefault'))];
                        if (response.data.result) {
                            for (let item in response.data.result) {
                                var id = response.data.result[item].id;
                                var prodname = response.data.result[item].productname;
                                var quantity = response.data.result[item].quantity;
                                var unitcost = response.data.result[item].unitcost;
                                var descripcion = response.data.result[item].description;
                                var applytax = response.data.result[item].applytax;
                                let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'))
                                let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
                                for (let i in TaxesLists) {
                                    if (TaxesLists[i].id == response.data.result[item].applytax) {
                                        taxOpcID = TaxesLists[i];
                                    }
                                }
                                ProdList.push({
                                    value: id,
                                    text: prodname,
                                    unitcost: unitcost,
                                    descripcion: descripcion,
                                    qty: quantity,
                                    taxOpc: taxOpcID,
                                    applytax: applytax,
                                });
                            }
                        }
                        t.ProdServiceLists = ProdList;
                        localStorage.setItem('ProdServiceLists', JSON.stringify(this.ProdServiceLists));
                        t.setDateStorage('ProdServiceListsDate');
                        // console.log("ProdList ", this.ProdServiceLists);
                        if(load==undefined){
                            t.addNewLinePrimary();
                            t.isLoading = false;
                        }
                        t.$emit('loadingData', {name: 'getProdservices', status:'finish'});
                        resolve(true);
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        t.$emit('loadingData', {name: 'getProdservices', status:'error'});
                        console.log(error);
                        resolve(false);
                    });
                }else{
                    t.ProdServiceLists = JSON.parse(localStorage.getItem('ProdServiceLists'));
                    if(localStorage.getItem('ProdServiceListsDate')==null){
                        t.setDateStorage('ProdServiceListsDate');
                    }
                    // console.log('ProdServiceLists localStorage');
                    if(load==undefined){
                        t.addNewLinePrimary();
                        t.isLoading = false;
                    }
                    t.$emit('loadingData', {name: 'getProdservices', status:'finish'});
                    resolve(true);
                }
            });
        },
        getModelProductServ(){
            let t = this;
            let ProdServiceLists = JSON.parse(localStorage.getItem('ProdServiceLists'));
            let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
            const ProdList = [];
            for (let item in ProdServiceLists) {
                var id = ProdServiceLists[item].value;
                var prodname = ProdServiceLists[item].text;
                var quantity = ProdServiceLists[item].qty;
                var unitcost = ProdServiceLists[item].unitcost;
                var descripcion = ProdServiceLists[item].descripcion;
                var applytax = ProdServiceLists[item].applytax;
                let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'));
                for (let i in TaxesLists) {
                    if (TaxesLists[i].id == ProdServiceLists[item].applytax) {
                        taxOpcID = TaxesLists[i];
                    }
                }
                ProdList.push({
                    value: id,
                    text: prodname,
                    unitcost: unitcost,
                    descripcion: descripcion,
                    qty: quantity,
                    taxOpc: taxOpcID,
                });
            }
            return ProdList;
        },
        addNewLinePrimary() {
            let t = this;
            if (t.form.actionNname == "create") {
                t.addGroup();
                let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
                let ProdList = t.getModelProductServ();
                t.itemProducts.push({
                    options: ProdList,
                    taxlistOption: TaxesLists,
                    model: JSON.parse(localStorage.getItem('productDefault')),
                });
                localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
            }
        },
        saveGroupStore(groupItem){
            // Filtrar el array para eliminar valores vacios o null
            const cleanGroupItem = groupItem.filter(item => item != null && item !== "" && !Number.isNaN(item));
            localStorage.setItem('groupItem', JSON.stringify(cleanGroupItem));
            // console.log('actualizando productos en addGroup', cleanGroupItem)
        },
        addGroup() {
            let t = this;
            let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'));
            let groupItem = JSON.parse(localStorage.getItem('groupItem'));
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
            let itemProducts = JSON.parse(localStorage.getItem('itemProducts')); // traemos
            let itemGroup = {
                value: t.idGroup,
                text: "",
                descripcion: "Uncategorized Group",
                unitcost: 0,
                qty: 0,
                taxOpc: taxOpcID,
                applytax: 0,
                prices: 0,
                totalGroup: 0
            };
            itemProducts.push({
                options: [itemGroup],
                taxlistOption: [],
                model: itemGroup,
            });
            t.itemProducts = itemProducts;
            let newGroupidx = t.itemProducts.length>0 ? t.itemProducts.length-1 : 0;
            itemGroup.itemGroup = newGroupidx
            groupItem[newGroupidx]=itemGroup;
            localStorage.setItem('groupItem', JSON.stringify(groupItem)); // guardamos nueva linea
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts)); // guardamos nueva linea
        },
        addNewLineWithGroup() {
            let t = this;
            t.addGroup();
            let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
            let ProdList = t.getModelProductServ();
            t.itemProducts.push({
                options: ProdList,
                taxlistOption: TaxesLists,
                model: JSON.parse(localStorage.getItem('productDefault')),
            });
            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
        },
        addLineFromGroup(index){
            let t = this;
            let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
            let ProdList = t.getModelProductServ();
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
            let itemProducts = JSON.parse(localStorage.getItem('itemProducts'));
            let newitem = {
                options: ProdList,
                taxlistOption: TaxesLists,
                model: JSON.parse(localStorage.getItem('productDefault')),
            };
            this.itemProducts.splice(index+1, 0, newitem);
            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
        },
        lineSeparator() {
            let t = this;
            let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'));
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
            let itemProducts = JSON.parse(localStorage.getItem('itemProducts')); // traemos
            itemProducts.push({
                options: [{
                    value: t.idseparador,
                    text: '',
                    unitcost: 0,
                    descripcion: 'Simple text',
                    qty: 0,
                    taxOpc: taxOpcID,
                    prices: 0
                }],
                taxlistOption: [],
                model: {
                    value: t.idseparador,
                    text: "",
                    descripcion: "Simple text",
                    unitcost: 0,
                    qty: 0,
                    taxOpc: taxOpcID,
                    applytax: 0,
                    prices: 0
                },
            });
            t.itemProducts = itemProducts;
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts)); // guardamos nueva linea
        },
        continueDes(index, limit) {
            let t = this;
            let desc = t.itemProducts[index].model.descripcion;
            if(desc!=null && desc!=''){
                let div = desc.length / limit;
                if(!Number.isInteger(div)){
                    div= Number(div.toFixed(0)) + 1;
                }
                let newDesc = desc.slice(0, limit);
                let desCarry = desc.replace(newDesc, '');
                t.itemProducts[index].model.descripcion = newDesc;
                if(div>1){
                    // console.log('desc div', div)
                    for (let i = 0; i < div; i++) {
                        let aggDesc = desCarry.slice(0, limit);
                        if(aggDesc.length>0){
                            // console.log('desc...', aggDesc)
                            let taxOpcID = JSON.parse(localStorage.getItem('taxDefault'));
                            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
                            let itemProducts = JSON.parse(localStorage.getItem('itemProducts'));
                            itemProducts.push({
                                options: [{
                                    value: t.idcontinue,
                                    text: '',
                                    unitcost: 0,
                                    descripcion: aggDesc,
                                    qty: 0,
                                    taxOpc: taxOpcID,
                                    prices: 0
                                }],
                                taxlistOption: [],
                                model: {
                                    value: t.idcontinue,
                                    text: "",
                                    descripcion: aggDesc,
                                    unitcost: 0,
                                    qty: 0,
                                    taxOpc: taxOpcID,
                                    applytax: 0,
                                    prices: 0
                                },
                            });
                            this.itemProducts = itemProducts;
                            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
                            desCarry = desCarry.replace(aggDesc, '');
                        }
                    }
                }
            }
        },
        addNewLine() {
            let t = this;
            let TaxesLists = JSON.parse(localStorage.getItem('TaxesLists'));
            let ProdList = t.getModelProductServ();
            localStorage.setItem('itemProducts', JSON.stringify(t.itemProducts));//guardamos cambios
            let itemProducts = JSON.parse(localStorage.getItem('itemProducts'));
            itemProducts.push({
                options: ProdList,
                taxlistOption: TaxesLists,
                model: JSON.parse(localStorage.getItem('productDefault')),
            });
            this.itemProducts = itemProducts;
            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
        },
        removeItem(index) {
            this.itemProducts.splice(index, 1);
            localStorage.setItem('itemProducts', JSON.stringify(this.itemProducts));
        },
        valiEnter(e){
            var code = (e.which) ? e.which : e.keyCode; // tecla enter
            if (code === 13) {
                // Obtener el contenido actual del textarea
                const text = e.target.value;
                // Dividir las lineas basandonos en saltos de linea
                const lines = text.split("\n");
                // Verificar la última linea
                const currentLine = lines[lines.length - 1];
                // Permitir salto de linea solo si la linea actual tiene mas de 2 caracteres
                if (currentLine.length <= 2) {
                    e.preventDefault(); // Evitar que se añada un salto de linea
                }
            }
        },
        getProductsOfEstByID(){
            let t = this;
            let groupItem = JSON.parse(localStorage.getItem('groupItem'));
            for (let item in t.itemsprod) {
                let taxfil = t.TaxesLists.filter(
                    (taxfil) => taxfil.id == t.itemsprod[item].tax_id
                );
                let newObj = taxfil.reduce((a, b) => Object.assign(a, b), {});
                let taxOpcDefault =JSON.parse(localStorage.getItem('taxDefault'));
                let isEmptyObj = !Object.keys(newObj).length;
                let unitcost = t.itemsprod[item].unitcost;
                let qty = t.itemsprod[item].qty;
                let itemModel = {
                    value: t.itemsprod[item].product_id,
                    unitcost: unitcost,
                    text: t.itemsprod[item].product_name,
                    descripcion: t.itemsprod[item].description,
                    qty: qty,
                    taxOpc: isEmptyObj ? taxOpcDefault : newObj,
                    price: t.itemsprod[item].price
                };
                t.itemProducts.push({
                    options: t.ProdServiceLists,
                    taxlistOption: t.TaxesLists,
                    model: itemModel,
                });
                if(itemModel.value==t.idGroup){
                    itemModel.totalGroup = t.itemsprod[item].price
                    // console.log('item grupo', itemModel)
                    groupItem[t.itemProducts.length-1]=itemModel;
                    localStorage.setItem('groupItem', JSON.stringify(groupItem));
                }
            }
        },
        NewProducts(item, index) {
            let t = this;
            t.FormAddPS.index_modal = index;
            t.$emit('addProdct', {
                FormAddPS: t.FormAddPS,
                TypeofAccount: t.TypeofAccount,
                TaxesLists: t.TaxesLists,
                formList: t.itemProducts,
            });
        },
        isDragging(t){
            // console.log('Start move')
            return t;
        },
        endDragging(){
            let t = this;
            // console.log('endDragging')
            let groupItem = [];
            for (let item in t.itemProducts) {
                if(t.itemProducts[item].model.value==t.idGroup){
                    groupItem[item] = t.itemProducts[item];
                }
            }
            localStorage.setItem('groupItem', JSON.stringify(groupItem));
        },
        isEmptyNaN(val){
            if (isNaN(val)){ return true;}
            else if (val==null){ return true;}
            else if (val==''){ return true;}
            else{ return false;}
        },
        quantityIsInteger(e) {
            var code = (e.which) ? e.which : e.keyCode;
            if((code>=48 && code<=57) || code==46) { // is a number.
                return true;
            } else{ // other keys.
                // console.log('other keys', code);
                return e.preventDefault();
            }
        },
        isInterger(e){
            let t=this;
            // console.log(e.target.value)
            if (t.isEmptyNaN(e.target.value)) {e.target.value = '0.00';}
            setTimeout(() => {
                // t.calcPrices(t.itemProducts);
            }, 500);
            e.target.value = parseFloat(e.target.value.replace(/,/g, "."))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, "");
        },
        lengMax(e, limit) {
            if (e.target.value.length > limit) {
                e.target.value = e.target.value.slice(0, limit);
            }
        },
        calcPriceItem(i){
            let t = this;
            let qty = t.itemProducts[i].model.qty;
            let unitcost = t.itemProducts[i].model.unitcost;
            let precioProduct = 0;
            if(qty>0 || unitcost>0){
                precioProduct = (qty * unitcost);
            }
            return precioProduct;
        },
        calcPrices(m){
            let t = this;
            let groupIdx = 0;
            let groupItem = JSON.parse(localStorage.getItem('groupItem'));
            // este metodo se llama desde el watch y el metodo isInterger
            for (let i in m) {
                let qty = m[i].model.qty;
                let unitcost = m[i].model.unitcost;
                if(t.isEmptyNaN(qty)){qty='0.00';}
                if(t.isEmptyNaN(unitcost)){unitcost='0.00';}
                let currentGroup = null;
                if(t.itemProducts[i].model.totalGroup !== undefined){
                    groupIdx = i;
                    if(groupItem[groupIdx]){
                        groupItem[groupIdx].prices=0;
                        groupItem[groupIdx].totalGroup=0;
                        groupItem[groupIdx].groupIdx=groupIdx;
                    }
                }else{
                    let precioProduct = 0;
                    if(qty>0 || unitcost>0){
                        precioProduct = (qty * unitcost);
                    }
                    if(groupItem[groupIdx]){
                        groupItem[groupIdx].prices+=precioProduct;
                        groupItem[groupIdx].totalGroup+=precioProduct;
                        // console.log('groupItem', groupIdx, 'total', groupItem[groupIdx].totalGroup)
                    }
                }
            }
            localStorage.setItem('groupItem', JSON.stringify(groupItem));
        },
        calcTotalGroup(index){
            let t = this;
            t.calcPrices(t.itemProducts) // calcular precios
            let groupItem = JSON.parse(localStorage.getItem('groupItem'));
            // console.log('totalGroup', groupItem[index])
            if(groupItem.length>0 && groupItem[index]){
                return parseFloat(groupItem[index].totalGroup).toFixed(2);
            }else{
                // console.log('totalGroup', index)
                return '0.00';
            }
        }
    },
    computed: {
        ...mapGetters("core", { getOpenModal: "getOpenModal", getActionRefresh: "getActionRefresh" }),
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        validRemoveGroup(){
            let t = this;
            let list = t.itemProducts;
            if(list.length>1){
                let valid = 0;
                for (let i in list) {
                    if(list[i].model.value==t.idGroup){
                        valid++;
                    }
                }
                if(valid>1){
                    return true;
                }
            }
            return false;
        },
        validRemoveItem(){
            let t = this;
            let list = t.itemProducts;
            if(list.length>1){
                let valid = 0;
                for (let i in list) {
                    if(list[i].model.value==t.idGroup){
                        valid=1;
                    }
                    else if(list[i].model.value!=t.idGroup && list[i].model.value!=t.idseparador && list[i].model.value!=t.idcontinue){
                        valid++;
                    }
                }
                if(valid>2){
                    return true;
                }
            }
            return false;
        },
    }
};
</script>
<style scoped>
.onoffswitch {margin:0px;}
.onoffswitch-inner:before {
    content: "On";
    padding-right: 30px;
    background-color: #137eff;
    color: #ffffff;
}
</style>
