<template>
    <div class="totales-resumen">
        <table style="width:100%;">
            <tr>
                <td style=" width: 70px;">Subtotal:</td>
                <td></td>
                <td style="text-align: right;font-weight: bold; color: #00aa00;">
                    ${{ form.subtotal | formatMoney }}
                    <!-- <input type="hidden" class="form-control" v-model="form.subtotal" /> -->
                </td>
            </tr>
            <tr v-if="proptaxShow == 1 && taxesResumen.length > 0"><td colspan="3" style="border-top: 1px solid #dedede; padding:0px;"></td></tr>
            <tr v-if="proptaxShow == 1 && taxesResumen.length > 0">
                <td style=" width: 70px; vertical-align: top;"></td>
                <td colspan="2" style="text-align: right;">
                    <table style="width:100%;" border="0" v-if="form.totaltax>0">
                        <tr v-for="(iva, index) in taxesResumen" :key="index">
                            <td colspan="2">
                                <table width="100%" v-if="iva.total>0">
                                    <tr >
                                        <td align="left">
                                            <div style="padding-right: 10px;">
                                                <b>
                                                    <span>{{ iva.name }}</span>
                                                    ({{ iva.porcentaje }}%)
                                                </b>
                                            </div>
                                        </td>
                                        <td align="right">
                                            ${{ iva.total | formatMoney }}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr style="display:none;">
                            <td colspan="2" style="border-bottom: 1px solid #dedede"></td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr v-if="form.totaltax>0" style="display:none;">
                <td style=" width: 70px; vertical-align: top;">Taxes:</td>
                <td colspan="2" style="text-align: right;"><b>${{ form.totaltax | formatMoney }}</b></td>
            </tr>
            <tr><td colspan="3" style="border-bottom: 1px solid #dedede; padding:0px;"></td></tr>
            <tr class="resumen_discount">
                <td style="padding:10px 0;">Discount:</td>
                <td style="padding:10px 0;width: 200px;">
                    <input type="text" :class="['form-control', (disabledStatus) ? 'disabled' : '']"
                        :disabled="disabledStatus"
                        style="text-align: right; width:80px;display:inline-block; margin-right: 10px;"
                        placeholder="E.g. 1.00" v-model="form.discount_mount" step="0.01" min="0" max="9999999.99"
                        maxlength="11" @keypress="isInteger" @change="vDiscount" />
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" v-model="form.discount_type"
                                :disabled="disabledStatus" value="2" /> $
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <label class="form-check-label">
                            <input class="form-check-input" type="radio" v-model="form.discount_type"
                                :disabled="disabledStatus" value="1" /> %
                        </label>
                    </div>
                </td>
                <td class="discountTotal" :rowspan="(form.discount_total > 0) ? 2 : 1">
                    <b>${{ (form.discount_total * -1) | formatMoney }}</b>
                </td>
            </tr>
            <tr v-if="form.discount_total > 0">
                <td colspan="2" class="nameDiscount">
                    <textarea placeholder="Discount name or description (optional)" class="form-control"
                        style="height: 70px; display: inline-block;width: 280px;" v-model="form.discount_name"
                        maxlength="101" @keyup="lengMax($event, 100)" @keypress="noEnter" @change="setUpdate"></textarea>
                    <span class="maxSize">
                        {{ (form.discount_name != null) ? form.discount_name.length : 0 }}/100
                    </span>
                </td>
            </tr>
            <tr><td colspan="3" style="border-top: 1px solid #dedede; padding:0px;"></td></tr>
            <tr>
                <td colspan="2" style="width: 70px;font-weight: bold;font-size: 18px;">
                    Total:
                </td>
                <td id="total" style="">
                    ${{ form.total | formatMoney }}
                    <!-- <input type="hidden" class="form-control" v-model="form.total" /> -->
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props: [
        "controlLoad",
        "propForm",
        "propItemsprod",
        "proptaxShow",
        "propgroupShow",
        "propTaxesResumen",
        "propdisabledSteps",
    ],
    components: {
    },
    data() {
        return {
            form: {
                subtotal: 0,
                discount_type: 2,
                discount_mount: 0.00,
                discount_total: 0.00,
                discount_name: '',
                total: 0,
                totaltax: null,
            },
            disabledStatus: this.propdisabledSteps?.customer,
            itemProducts: this.propItemsprod,
            taxesResumen: this.propTaxesResumen,
        };
    },
    methods: {
        isEmptyNaN(val){
            if (isNaN(val)){ return true;}
            else if (val==null){ return true;}
            else if (val==''){ return true;}
            else{ return false;}
        },
        isInteger(e) {
            var code = (e.which) ? e.which : e.keyCode;
            if((code>=48 && code<=57) || code==46) { // is a number.
                return true;
            } else{ // other keys.
                // console.log('other keys', code);
                return e.preventDefault();
            }
        },
        noEnter(e){
            var code = (e.which) ? e.which : e.keyCode;
            if(code == 13){
                return e.preventDefault();
            }
        },
        lengMax(e, limit) {
            if (e.target.value.length > limit) {
                e.target.value = e.target.value.slice(0, limit);
            }
        },
        vDiscount(e){
            let t=this;
            if (t.isEmptyNaN(e.target.value)) {e.target.value = '0.00';}
            //.replace(/,/g, ".")
            e.target.value= parseFloat(e.target.value).toFixed(2);
            // .toString()
            // .replace(/\B(?=(\d{3})+(?!\d))/g, "");
            let val = e.target.value;
            let valResult = val;
            let msj = '';
            let validInt = new RegExp(/^[0-9.]+$/); 
            // si es diferente de numero , retornar cero
            // console.log('vDiscount', val)
            if(!validInt.test(val)){
                if(val != null && val!=''){
                    msj="Discount, only numbers allowed.";
                }
                valResult = 0;
            }
            // si es NaN
            else if(t.isEmptyNaN(val)){
                msj="Incorrect amount, you may be adding disallowed symbology.";
                valResult = 0;
            }
            // si es menor de cero, retorna cero
            else if(val < 0){
                msj="Discount cannot be less than zero";
                valResult = 0;
            }
            //validaciones donde descuento es porcentaje y es mayor de 99.99, retornar cero
            else if(t.form.discount_type==1 && val > 0 && val >99.99){
                msj="Discount cannot be greater than 99.99%";
                valResult = 0;
            }
            // validaciones siendo un monto y si es mayor a subtotal, retornar cero
            else if(t.form.discount_type==2 && val > 0 && parseInt(val) >= parseInt(t.form.subtotal)){
                msj="Discount cannot be greater than or equal to the subtotal.";
                valResult = 0;
            }
            if(msj.length > 0){this.$emit('setAlertError', msj);}
            // e.target.value = Number(valResult).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return e.target.value;
        },
        showResumenTax(itemProducts, valDiscount, subtotal){
            // console.log('showResumenTax')
            let t=this;
            //reiniciar totales de tax a cero
            if (t.taxesResumen.length > 0) {
                for (let e in t.taxesResumen) {
                    t.taxesResumen[e].total = 0;
                }
            }
            // calcular totales
            let obj = itemProducts;
            for (let i in obj) {
                if (obj[i].model.taxOpc != 0) {
                    if (t.taxesResumen.length > 0) {
                        for (let e in t.taxesResumen) {
                            if (t.taxesResumen[e].id == obj[i].model.taxOpc.id) {
                                let unitcost = obj[i].model.unitcost;
                                let qty = obj[i].model.qty;
                                let valTax = obj[i].model.taxOpc.name;
                                if(valDiscount > 0){
                                    //calculamos el valor en % equivalente al subtotal
                                    let defragDiscount= (unitcost / subtotal) * 100;
                                    let defragDiscVal = valDiscount * defragDiscount / 100;
                                    // console.log('descuento en producto', defragDiscVal)
                                    unitcost = unitcost - defragDiscVal
                                    // console.log('new unitcost', unitcost)
                                }
                                let precioTotal = (unitcost * qty);
                                let importeTax = (precioTotal * valTax) / 100;
                                if (!t.isEmptyNaN(importeTax)) {
                                    let TaxTotal = parseFloat(t.taxesResumen[e].total) + parseFloat(importeTax);
                                    t.taxesResumen[e].total = parseFloat(TaxTotal).toFixed(2);
                                    // console.log('taxesResumen', valTax, t.taxesResumen[e].total)
                                }
                            }
                        }
                    }
                }
            }
        },
        subTotal() {
            let t = this;
            if(t.itemProducts.length>0){
                return t.itemProducts.reduce((carry, item) => {
                    let unitcost = item.model.unitcost;
                    let qty = item.model.qty;
                    let amount = parseFloat(carry) + parseFloat(qty) * parseFloat(unitcost)
                    let stt = item.model.prices;
                    if (t.isEmptyNaN(amount)) {
                        amount = '0.00'
                    }else{
                        amount = parseFloat(amount).toFixed(2);
                    }
                    t.form.subtotal = amount
                    // console.log(' calc subtotal', t.form.subtotal)
                    return amount
                }, 0);
            }
            else{
                t.form.subtotal = '0.00';
                // console.log('else subtotal', t.form.subtotal)
                return '0.00';
            }
        },
        discount() {
            let t = this;
            // inicializo variables a utilizar
            let discount_value = '0.00';
            let mount = parseFloat(t.form.discount_mount);
            // console.log('calcular discount', mount)
            // obtener el valor del descuento para ello
            // primero valido si el descuento es en % o $
            if(mount > 0){
                // si descuento es mayor a cero, calculo
                if(t.form.discount_type==1){ // porcentaje
                   discount_value = (parseFloat(t.form.subtotal) * mount) / 100;
                }
                else if(t.form.discount_type==2){
                    // monto
                    discount_value = mount;
                }
            }
            // valido q no sea Nan
            if (t.isEmptyNaN(discount_value)) {discount_value = '0.00';}
            // si el calculo de descuento es igual o mayor al subtotal retorno cero o error
            if(discount_value >= parseFloat(t.form.subtotal)){discount_value = '0.00';}
            t.form.discount_total = parseFloat(discount_value).toFixed(2); // ajustamos decimales a 2
            // console.log('t.form.discount_total', t.form.discount_total)
            return t.form.discount_total;
        },
        totalTaxes() {
            let t = this;
            let totaltaxes = '0.00';
            if(t.proptaxShow==1){
                // solo si taxes esta activo, genero resumen de tax
                t.showResumenTax(t.itemProducts, t.form.discount_total, t.form.subtotal);
                if (t.taxesResumen.length > 0) {
                    for (let e in t.taxesResumen) {
                        totaltaxes=parseFloat(totaltaxes) + parseFloat(t.taxesResumen[e].total);
                        // console.log('totaltaxes +', t.taxesResumen[e].total)
                    }
                }
            }
            if (t.isEmptyNaN(totaltaxes)) {totaltaxes = '0.00';}
            t.form.totaltax = parseFloat(totaltaxes).toFixed(2);
            // console.log('t.form.totaltax = ', t.form.totaltax)
            return t.form.totaltax;
        },
        total() {
            let t = this;
            t.subTotal();
            t.discount();
            t.totalTaxes()
            let stt=(parseFloat(t.form.subtotal) - parseFloat(t.form.discount_total) + parseFloat(t.form.totaltax));
            if (t.isEmptyNaN(stt)) {stt = '0.00';}
            t.form.total = parseFloat(stt).toFixed(2);
            t.setUpdate(); // actualizar componente padre
            return t.form.total;
        },
        setUpdate(){
            let t = this;
            t.$emit('updateTotales', t.form);
        }
    },
    watch: {
        propItemsprod: {
            handler: function (newVal) {
                let t = this;
                t.itemProducts=newVal
                setTimeout(() => {t.total();}, 500);
                // console.log('detectando cambio en propItemsprod', newVal)
                return;
            },
            deep: true, // Necesario para observar cambios en los objetos dentro del array
            immediate: true, // Llamar al watch inmediatamente al cargar
        },
        propTaxesResumen: {
            handler: function (newVal) {
                let t = this;
                t.taxesResumen = newVal
                // setTimeout(() => {t.total();}, 500);
                // console.log('detectando cambio en propTaxesResumen', newVal)
                return;
            },
            deep: true, // Necesario para observar cambios en los objetos dentro del array
            immediate: true, // Llamar al watch inmediatamente al cargar
        },
        proptaxShow: function(){
            let t = this;
            // console.log('proptaxShow ', this.proptaxShow)
            setTimeout(() => {t.total();}, 500);
        },
        'form.discount_mount': function(){
            let t = this;
            setTimeout(() => {t.total();}, 500);
        },
        'form.discount_type': function(){
            let t = this;
            setTimeout(() => {t.total();}, 500);
        },
    },
    created(){
        // console.log('propForm ', this.propForm)
        this.form.discount_type = this.propForm.discount_type
        this.form.discount_mount = this.propForm.discount_mount
        this.form.discount_total = this.propForm.discount_total
        this.form.discount_name = this.propForm.discount_name
    }
};
</script>
