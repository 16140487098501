<template>
  <div class="content_iframe">
    <header-principal></header-principal>
    <iframe ref="iframe" :src="urlbSales+'?v='+version" @load="sendMessage" style="width:100%; height:99vh;border:0;overflow:hidden"></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderPrincipal from "@/core/views/Header";
export default {
  components: {
        HeaderPrincipal,
  },
  data(){
    return {
      urlbSales: window.urlbSales,
      version: new Date().getTime(),
    };
  },
  methods: {
    sendMessage() {
      this.$refs.iframe.contentWindow.postMessage(
        { type: "auth", token: localStorage.getItem('access_token_m') }, this.urlbSales
      );
    },
    listenToIframe(event) {
      if (event.origin !== this.urlbSales) return;
      console.log("Mensaje recibido de Vue 3:", event.data);
    }
  },
  mounted() {
    window.addEventListener("message", this.listenToIframe);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.listenToIframe);
  },
  computed: {
      ...mapGetters("core", { getPusher: "get_accitonPusher"}),
      ...mapGetters("core", { getHeader: "getHeader" }),
      ...mapGetters("core/session", { loggedIn: "loggedIn" })
  },
};
</script>
<style lang="css" scoped>
body, .content_iframe {overflow:hidden;}
</style>