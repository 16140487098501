<template>
    <div class="col-lg-7 col-md-7">
        <div class="row">
            <div class="col-lg-8">
                <div class="form-group">
                    <label for="nameEstInput">Invoice Name <small>(Optional)</small></label>
                    <input
                        type="text"
                        :class="['form-control', disabledSteps.customer ? 'disabled' : '']"
                        :disabled="disabledSteps.customer"
                        id="nameEstInput"
                        maxlength="60"
                        v-model="document_name"
                        placeholder="Document name"
                        @change="updateDataForm"
                    />
                </div>
                <div class="form-group showtooltip">
                    <textarea
                        v-model="job_information"
                        id="job_information"
                        :class="['form-control', disabledSteps.customer ? 'disabled' : '']"
                        maxlength="200"
                        :disabled="!jobinfomationcheck || disabledSteps.customer"
                        :placeholder="jobinfomationtitle+': '+((jobinfomationcheck) ? 'Let your client know what this Invoice is for:' : 'Field disabled, set in settings')"
                        @change="updateDataForm"
                    ></textarea>
                    <!-- @keydown.enter.prevent="noEnter" -->
                    <span class="maxSize">
                        {{ job_information ? job_information.length : 0 }}/200
                    </span>
                </div>
            </div>
            <date-doc-due-terms
            v-if="termsList.length>0"
            :propdate="dateDoc"
            :propdue_date="due_date"
            :proptermsList="termsList"
            :propterm_id="term_id"
            @updateDates="getProps"

            />
        </div>
    </div>
</template>

<script>
import dateDocDueTerms from './dateDocDueTerms.vue';
export default {
    components: {"date-doc-due-terms":dateDocDueTerms},
    props: [
        "controlLoad",
        "propdisabledSteps",
        "propdocument_name",
        "propjob_information",
        "propfecdoc",
        "propfecdue",
        "propForm",
    ],
    data() {
        return {
            disabledSteps: this.propdisabledSteps,
            document_name: this.propdocument_name,
            job_information: this.propjob_information,
            dateDoc: this.propfecdoc,
            due_date: this.propfecdue,
            form: this.propForm,
            jobinfomationcheck: true, // Consider starting as null or false if you expect it to be updated from API
            jobinfomationtitle: "Job information",
            term_id: 0,
            termsList: [],
        }
    },
    created(){
        this.getInvoiceSetting();
    },
    methods: {
        updateDataForm() {
            this.$emit('updateProps', {
                document_name: this.document_name,
                job_information: this.job_information,
                term_id: this.term_id,
                dateDoc : this.dateDoc,
                due_date : this.due_date,
            });
        },
        getProps(data){
            this.dateDoc = data.dateDoc;
            this.due_date = data.due_date;
            this.term_id = data.term_id;
            this.updateDataForm();
        },
        noEnter(e) {
            return false
        },
        getTermsList() {
            this.$emit('loadingData', { name: 'getTermsList', status: 'pending' });
            window.billing
                .get("terms/" + window.localuserdata)
                .then((response) => {
                    if (response.data.length) {
                        this.termsList = response.data;
                    }
                    this.$emit('loadingData', { name: 'getTermsList', status: 'finish' });
                    this.updateDataForm();
                })
                .catch((error) => {
                    console.log(error);
                    this.$emit('loadingData', { name: 'getTermsList', status: 'error' });
                });
        },
        getInvoiceSetting() {
            this.$emit('loadingData', { name: 'getInvoiceSetting', status: 'pending' });
            window.billing
                .get("invoice/setting/" + window.localuserdata)
                .then((response) => {
                    if (response.data.result.length) {
                        let r = response.data.result[0];
                        this.jobinfomationcheck = r.jobinfomationcheck;
                        this.jobinfomationtitle = r.jobinfomationtitle;
                        if (this.form.actionNname == "create") {
                            this.job_information = r.jobinfomationtext;
                            this.term_id = r.term_id;
                        }
                    }
                    this.$emit('loadingData', { name: 'getInvoiceSetting', status: 'finish' });
                    this.getTermsList();
                })
                .catch((error) => {
                    console.log(error);
                    this.$emit('loadingData', { name: 'getInvoiceSetting', status: 'error' });
                });
        },
    },
    watch: {
    },
};
</script>
