import iframe_bSales from '../views/iframebSales.vue'
const nameApp = 'bSales';
export default [
	{
		path: '/bsales',
		folder: iframe_bSales,
		meta: {requiresAuth: true},
		name: 'bSales',
		// children: [
		// 	{
		// 		path: '/',
		// 		name: 'Tutor',
		// 		folder: dashView
		// 	},
		// ]
	}
]
