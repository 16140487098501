<template>
    <div class="card">
        <div class="card-header"><h3>Select Sales Rep:</h3> </div>
        <div class="card-body">
            <table class="table table-bordered table-inverse table-hover" id="salesRep">
                <thead>
                    <th style="width: 50px;">Check</th>
                    <th style="width: 50px;">Avatar</th>
                    <th>Sales Rep.</th>
                    <!-- <th style="width: 80px;">Send SMS</th> -->
                </thead>
                <tbody v-for="(i, index) in options" :key="index">
                    <td >
                        <div class="onoffswitch">
                            <input
                                type="checkbox"
                                class="onoffswitch-checkbox"
                                :id="'salesRep-'+i.account_id"
                                v-model="i.checkSalesRep"
                                @change="setSalesRep(i)"
                            />
                            <label class="onoffswitch-label" :for="'salesRep-'+i.account_id">
                                <span class="onoffswitch-inner"></span>
                                <span class="onoffswitch-switch"></span>
                            </label>
                        </div>
                    </td>
                    <td>
                        <span :class="['avatarUser', 'bg-avatar']" :style="'background-color:'+i.classColor+';'">
                            {{ i.pref_name }}
                        </span>
                    </td>
                    <td>
                        <span class="option__title" style="">
                            <strong style="color:#555;  font-size: 12px; display: block;" v-if="i.account_id == owner">
                                {{i.owner }} <small>(Owner)</small>
                            </strong>
                                {{ i.firstname }} {{ i.lastname }}<br />
                                <span class="option__small" style="">
                                    {{ i.email }}
                                </span>
                        </span>
                    </td>
                    <!-- <td class="sendNotisSMS">
                        <div class="onoffswitch">
                            <input
                                type="checkbox"
                                class="onoffswitch-checkbox"
                                :id="'noti-'+i.account_id"
                                :value="8"
                            />
                            <label class="onoffswitch-label" :for="'noti-'+i.account_id">
                                <span class="onoffswitch-inner"></span>
                                <span class="onoffswitch-switch"></span>
                            </label>
                        </div>
                    </td> -->
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            org_id: window.localorgdata,
            owner: window.master_client,
            userIdAssign: null,
            userlist: [],
            options: [],
            item: null,
            isOpen: false,
        };
    },
    created() {
        let t = this;
        let g = t.$store.getters['core/getOpenModal'];
        t.userIdAssign = g.data.userlist.find(r => { return r.account_id == g.data.item.seller_ids});
        t.item = g.data.item;
        t.userlist = g.data.userlist;
        t.resetOptions()
        console.log('userIdAssign', this.userIdAssign)
    },
    methods: {
        resetOptions(){
            let t = this;
            t.options = [];
            setTimeout(() => {
                if (t.userlist.length > 0) {
                    t.userlist.filter(r => {
                        r.checkSalesRep = false;
                        r.checkSMS = 0;
                        if(t.userIdAssign?.account_id == r.account_id){
                            r.checkSalesRep = true;
                            r.checkSMS = 1;
                        }
                        t.options.push(r);
                    })
                }
            }, 400);
        },
        setSalesRep(val){
            let t = this;
            console.log('checkSalesRep', val.checkSalesRep)
            t.userIdAssign = null;
            if(!val.checkSalesRep){
                val.checkSMS = 0;
                t.saveSalesRep(val);
            }else{
                t.$swal({
                    title: "Are you sure to change Sales Resp.?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Yes",
                    html: `<label>
                    <input type="checkbox" value="1" id="sendNotiSMS" required>
                    <span class="swal2-label">Send SMS notification</span>
                    </label>`,
                }).then((result) => {
                    if (result.value) {
                        val.checkSMS = document.querySelector("#sendNotiSMS").checked ? 1 : 0;
                    }
                    t.saveSalesRep(val)
                });
            }
        },
        saveSalesRep(val){
            let t = this;
            if(val.checkSalesRep){
                t.userIdAssign = val
            }
            t.resetOptions();
            console.log('saveSalesRep', t.userIdAssign)
            window.master.post("assignUsersSales", {
                id: t.item.id,
                seller_ids: val.account_id,
                user_id: window.master_client,
                org_id: window.localorgdata,
                checkSMS: val.checkSMS,
            })
            .then((response) => {
                console.log('assignUsersSales', response.data);
                setTimeout(() => {
                    t.$parent.close();
                }, 200);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
#salesRep th {font-weight: bold;}
#salesRep td {vertical-align: top;}
.avatarUser {
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    margin: 0px;
    padding-top: 5px;
    border: 1px solid #ccc;
    position: relative;
    z-index: 1;
    border-radius: 10%;
    font-size: 18px;
    letter-spacing: -1px;
    font-weight: 900;
    background-color: #c4c4c4;
    color: #fff;
    text-transform: uppercase;
}
span.option__title {
    line-height: 1;
    font-size: 18px;
    color: #000;
    .option__small{font-size: 12px;color: #aaa;}
}
.onoffswitch-inner:before {
    padding-right: 0px;
    padding-left: 10px;
}
</style>
