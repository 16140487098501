<template>
    <div class=" col-5 form-group col-customer_message"
        style="display: flex;flex-direction: column;justify-content: flex-end;margin-bottom: 0;">
        <label for="customer_message">Customer Message <small>(Optional)</small></label>
        <textarea id="customer_message"
            :class="['form-control customer_message', (disabledState) ? 'disabled' : '']"
            :disabled="disabledState" v-model="customer_message"
            @change="setUpdate"
            placeholder="We hope to continue doing business with you." maxlength="300"></textarea>
        <span class="maxSize">
            {{ (customer_message != null) ? customer_message.length : 0 }}/300
        </span>
    </div>
</template>
<script>
export default {
    props: [
        "controlLoad",
        "propForm",
        "propdisabledSteps",
    ],
    components: {
    },
    data() {
        return {
            customer_message: this.propForm?.customer_message || null,
            disabledState: this.propdisabledSteps?.customer || false,
        };
    },
    methods: {
        setUpdate(){
            console.log('event change', this.customer_message)
            this.$emit('updateProps', this.customer_message);
        },
    }
};
</script>
