<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view/>
    </transition>
    <notifications
      group="refreshApp"
      :width="340"
      animation-name="v-fade-left"
      position="top center"
      class="refreshApp"
    >
      <template slot="body">
        <div class="refresh-template">
          <div class="refresh-template-content" @click="refreshApp">
            <div class="refresh-template-title">
              <span class="icon">
                <button class="btn-refresh-sync">
                  <i class="fas fa-sync-alt"></i>
                </button>
              </span>
              <span class="title">New content is available; please refresh.</span>
            </div>
          </div>
        </div>
      </template>
    </notifications>
    <div class="vue-notification-group offLine" style="width: 340px; top: 0px; right: 0px;" v-if="offLine">
      <div data-id="0" class="vue-notification-wrapper" style="transition: all 300ms ease 0s;">
        <div class="offline-template">
          <div class="offline-template-content">
            <div class="offline-template-title">
              <span class="icon">
                <button class="btn-offline-cloud-slach">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" viewBox="0 0 16 16" class="bi bi-cloud-slash">
                    <path fill-rule="evenodd" d="M3.112 5.112a3.125 3.125 0 0 0-.17.613C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13H11l-1-1H3.781C2.231 12 1 10.785 1 9.318c0-1.365 1.064-2.513 2.46-2.666l.446-.05v-.447c0-.075.006-.152.018-.231l-.812-.812zm2.55-1.45-.725-.725A5.512 5.512 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773a3.2 3.2 0 0 1-1.516 2.711l-.733-.733C14.498 11.378 15 10.626 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3c-.875 0-1.678.26-2.339.661z"></path>
                    <path d="m13.646 14.354-12-12 .708-.708 12 12-.707.707z"></path>
                  </svg>
                </button>
              </span>
              <span class="title">NO CONNECTION</span>
              <div class="offline-close">
                <!-- <button class="close" @click="offLine=false"><i class="fa fa-times"></i></button> -->
              </div>
            </div>
            <div class="offline-template-message">Last updated: &#60; 1 minute ago.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '@/scss/styles.scss';
import "@/scss/app.scss"
import '@/core/plugins/tdynamic/css/tdynamic.css';
import Echo from "laravel-echo"
var Pusher = require('pusher-js');
import { mapGetters } from "vuex";
export default {
  data () {
    return {
      transitionName: 'slide-left',
      getActions: [],
      // refresh variables
      refreshing: false,
      registration: null,
      updateExists: false,
      offLine: false,
      nIntervId: null,
      production: '60c69620539ec959cfd0',
      beta: '2a2f345b9b6442c8b0ab',
      serverPusher: '2a2f345b9b6442c8b0ab',
      version: 'beta',
      //version: 'production',
      version_app: window.version_app
    }
  },
  created() {
    if(this.version=='production'){ this.serverPusher = this.production;}
    else{ this.serverPusher = this.beta;}
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      //window.location.reload()
    });
    let rutaAct= this.$router.currentRoute.path.split('/'); //this.$router.currentRoute.name
    this.addClassBodyToUrl(rutaAct);
  },
  mounted()
  {
    Pusher.logToConsole = false;        
    let t= this;
    window.pusher = new Pusher(t.serverPusher, {cluster: 'us2'});//beta
    var registerEventos = [
      'App\\Events\\SessionPusherEvents',
      'App\\Events\\Jobs\\ActivityJobsEvent',
      'App\\Events\\Billing\\BillingEvent',
    ];
    var channelSession = window.pusher.subscribe('channel-session');
    channelSession.bind(registerEventos[0], function(data) {
    })
    .bind('pusher:subscription_succeeded', function(members) {
    });
    var channel = window.pusher.subscribe('channel-jobs');
    channel.bind(registerEventos[1], function(data) {
    })
    .bind('pusher:subscription_succeeded', function(members) {
    });    
    var channelBilling = window.pusher.subscribe('channel-billing');
    channelBilling.bind(registerEventos[2], function(data) {
    })
    .bind('pusher:subscription_succeeded', function(members) {
    });
    var callback = function(eventName, data) {
      //console.log('pusher callback', eventName, data);
      if (registerEventos.indexOf(eventName) > -1) {
        if (data.pusher) {
          let d = data.pusher;
          if(d.action === "versionCache") {
            t.validVersionCache(d.version);
          }
          else if (typeof d != "object") { d = eval('('+d+')');}
          t.$store.commit('core/set_accitonPusher', d);
        }
        else if(data.action === "versionCache") {
          let d = data;
          t.validVersionCache(d.version);
        }
      }       
    };
    // Send valid connection
    setInterval(function(){
      t.conexionSERVER();
    }, 30000);
    //bind to all events on the channel
    window.pusher.bind_global(callback);
    this.$receiveMessage((e) => {
      // receive data
      //console.log('receiveMessage App', e.data)
    }, false);
    navigator.serviceWorker.addEventListener('message', event => {
      let sw_version = event.data.versionCache;
      console.log('validando SW', sw_version)
      if(localStorage.getItem('versionCache') != sw_version) {
        console.log('serviceWorker desactualizado', event.data);
        navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
          for(let registration of registrations) {
            registration.unregister().then((boolean) => {
              if(boolean){
                console.log('unregister SW')
                t.validVersionCache(sw_version)
              }
            });
          }
        });
      }
    });
    window.addEventListener('online', this.conexionSERVER);
    window.addEventListener('offline', this.conexionSERVER);
    // this.resetActivityCheck();
  }, 
  methods : {
    addClassBodyToUrl(rutaAct){
      //console.log('rutaAct', rutaAct, url)
      if(rutaAct.length>=3){
        if(rutaAct[2]=='biulder'){
          document.querySelector('.body').style.overflowY='hidden';
        }
        else{
          document.querySelector('.body').style.overflowY='initial';
        }
      }
    },
    sin_conexion(){      
      let t= this;
      console.log('sin conexion')
      console.log('SET_HEADER true')
      t.$store.commit("set_isLoading", true);
      t.offLine = true;
    },
    reconectar(){
      let t= this;
      console.log('reconectando...')
      t.$store.dispatch('core/session/validToken')
        .then(response => {
          console.log('ya hay conexion')
          console.log('SET_HEADER false')
          t.$store.commit("set_isLoading", false);
          t.offLine = false;
        });
    },
    conexionSERVER() {
      let t =this;
      if(!window.navigator.onLine){
        console.log('conexionSERVER', window.navigator.onLine);
        t.sin_conexion();
      }
      else if(t.offLine) { t.reconectar(); }
    },
    validVersionCache(_version) {
      if(localStorage.getItem('versionCache') != _version) {
          localStorage.setItem('updateCache', 1);
          this.updateExists = true;
          this.notiPushRefresh();
      }
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      this.notiPushRefresh();
    },
    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (this.registration && this.registration.waiting) {
        // send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
      localStorage.setItem('updateCache', 0);
      window.location.reload(true);
    },
    notiPushRefresh () {
      let t = this;
      if(t.$router.currentRoute.name != 'lockScreen'){
        // console.log('push refreshApp');
        t.$notify({group: 'refreshApp', clean: true});
        setTimeout(() => {
          if(t.updateExists){
            t.$notify({
              group: "refreshApp",
              type: "",
              title: "",
              text: "",
              duration: 3000000
            });
          }
        }, 800);
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    next()
  },
  watch: {
    '$route' (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      let rutaAct= to.path.split('/'); //this.$router.currentRoute.name
      let url = to.name;
      this.addClassBodyToUrl(rutaAct)
    },
    pushRefresh: function () {
      if (this.pushRefresh) {
        this.updateExists = true;
        this.notiPushRefresh();
      }
    },
  },
  computed: {
    ...mapGetters("core", { pushRefresh: "getNotiPushRefresh"}),
  }
};
</script>
<style lang="scss" >
</style>

