<template>
    <div class="col-lg-4" style="padding-right: 0px; padding-top: 25px;">
        <div class="form-group">
            <label for="datedoc">Date:</label>
            <div class="input-group">
                <button class="form-control show_date date_doc" @click="openDateDocPicker()">
                    <i class="far fa-calendar-alt"></i>
                    <span class="formatted-date">{{ formatDate(dateDoc) }}</span>
                </button>
                <input
                    type="date"
                    required
                    class="form-control date_hiden"
                    ref="datedoc"
                    v-model="dateDoc"
                    @change="updateDueDate"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="terms">Due Date:</label>
            <div class="input-group">
                <select
                    id="terms"
                    class="form-control"
                    v-model="term_id"
                    @change="addDays"
                >
                <option :value="0">Due on Receipt</option>
                    <option
                        v-for="term in termsList"
                        :key="term.id"
                        :value="term.id"
                    >
                        {{ term.name }}
                    </option>
                </select>
            </div>
            <div class="input-group">
                <div class="form-control show_date due_date">
                    <i class="far fa-calendar-alt"></i>
                    <span class="formatted-date">{{ formatDate(due_date) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "propdate",
        "propdue_date",
        "proptermsList",
        "propterm_id",
    ],
    data() {
        return {
            dateDoc: this.propdate,
            due_date_input: null,
            due_date: this.propdue_date,
            term_id: this.propterm_id,
            termsList: this.proptermsList,
        }
    },
    created(){
        // this.addDays();
    },
    methods: {
        validDateDoc() {
            this.dateDoc = new Date().toISOString().split("T")[0]; // Obtiene la fecha en formato YYYY-MM-DD
            // console.log('validDateDoc', this.dateDoc);
        },
        updateDataForm() {
            this.$emit('updateDates', {
                dateDoc: this.exportDate(this.dateDoc),
                due_date: this.exportDate(this.due_date),
                term_id: this.term_id,
            });
        },
        openDateDocPicker() {
            this.$refs.datedoc.showPicker(); // Abre el calendario
        },
        fragmetarFecha(dateValue) {
            if (!dateValue) return;
            // console.log('fragmetarFecha', dateValue);
            let dateSinHoras = (dateValue.indexOf('T')>-1) ? dateValue.split("T")[0] : dateValue;
            // console.log('dateSinHoras', dateSinHoras);
            let parts = dateSinHoras.split("-");
            // console.log('parts', parts);
            return parts;
        },
        formatDate(dateValue) {
            if (!dateValue) return;
            // console.log('formatDate');
            let parts = this.fragmetarFecha(dateValue);
            // console.log('parts', parts);
            let date = new Date(parts[0], parseInt(parts[1] - 1), parts[2]); // Año, Mes-1, Día
            return date.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "2-digit" }); // formato corto, Feb 1, 2021
        },
        exportDate(dateValue) {
            if (!dateValue) return;
            return dateValue + 'T23:31:04.000Z'; // Añade la hora
        },
        getValTerms(){
            var sumar = 1;
            if (this.term_id > 0) {
                for (let prop in this.termsList) {
                    if (this.termsList[prop].id == this.term_id) {
                        this.Due_Date = this.termsList[prop].name;
                        let dias = this.termsList[prop].name.split("NET ");
                        sumar = dias[1];
                    }
                }
            }
            return sumar;
        },
        addDays() {
            if (!this.dateDoc) return;
            let newDate = new Date(this.dateDoc);
            let sumar = parseInt(this.getValTerms());
            // console.log('sumar', sumar);
            newDate.setDate(newDate.getDate() + sumar);
            this.due_date = newDate.toISOString().split("T")[0]; // Obtiene la fecha en formato YYYY-MM-DD
            this.updateDataForm();
        },
        updateDueDate() {
            if (!this.dateDoc) return;
            // console.log('updateDueDate');
            let parts = this.fragmetarFecha(this.dateDoc);
            let date = parts[0]+'-'+parts[1]+'-'+(parts[2] > 9 ? parts[2] : '0'+parts[2]); // Año-(Mes-1)-Día
            this.due_date = date;
            this.due_date_input = date;
            this.addDays();
        },
    },
    watch: {
        propdate: function (newVal) {
            // this.dateDoc = newVal;
            // console.log('watch propdate', newVal);
        },
        dateDoc: function (newVal) {
            // console.log('watch dateDoc', newVal);
            if(newVal==null || newVal==''){
                this.validDateDoc();
            }
        },
        due_date_input() {
        },
    },
}
</script>
<style scoped>
.input-group > .form-control.show_date {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}
.input-group > .form-control.show_date span.formatted-date {margin-left: 15px;}
.input-group > .form-control.date_hiden {
    position: absolute;
    width: 100%;
    height: 37px;
    z-index: -1;
    pointer-events: none;
}
</style>
